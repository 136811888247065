import { mediaDown, mediaUp } from 'util/media-query';
import { ratio } from 'styles/mixins';
import styled from 'styled-components';
import Pipe from 'public/images/pipes.svg';
import RectDots from 'public/images/rect-dots.svg';
import fluid from 'util/fluid';
import Wrapper from 'components/wrapper';
import Button from 'components/button';
import rem from 'util/rem';
import Slider from 'components/slider';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        background: ${p => p.theme.colors.dark};
        overflow: hidden;

        ${mediaUp('desktop-small')} {
            position: fixed;
            top: 0;
            width: 100%;
            height: 100vh;
            min-height: auto;

            + section {
                margin-top: 100vh;
                position: relative;
                z-index: 1;
            }
        }
	`,

    TopDecoration: styled(Pipe)`
        position: absolute;
        top: 0;
        left: 0;
        width: max-content;
        height: fit-content;
        opacity: 0.15;
        pointer-events: none;
    `,

    Button: styled(Button)`
        font-size: ${fluid(16, 24)};
    `,

    HeaderStripe: styled.div`
        z-index: 3;
        width: 108vw; /* Ensure full viewport width */
        position: relative;
        top: 28%;
        transform: translateX(-10%);

         > div {
            width: 100vw !important;
            min-width: ${rem(690)};
        }


        ${mediaUp('phone-small')} {
            left: 0;
            top: 20%;
        }

        ${mediaUp('tablet')} {
            top: 45%;
        }

        ${mediaUp('desktop-large')} {
            transform: translateX(-20%);
        }
    @media screen and (min-width: 1700px) and (max-width: 1950px) {
        transform: translateX(-11%); 
    }
`,

    MobilePersona: styled.div`
        display: flex;
        flex-flow: column;
        gap: 20px;
    `,

    BottomDecoration: styled(RectDots)`
        position: absolute;
        right: ${rem(10)};
        top: ${rem(10)};
        width: ${fluid(60, 130)};
        z-index: 5;
        color: #F8F7F7;
        
        ${mediaUp('desktop-small')} {
            top: initial;
            bottom: ${rem(10)};
        }
    `,
    
    BottomDecorationMobile: styled(RectDots)`
        position: absolute;
        right: ${rem(10)};
        bottom: ${rem(10)};
        width: ${fluid(60, 130)};
        z-index: 5;
        
        ${mediaUp('phone')} {
            display: none;
        }
    `,

    TopContent: styled.div`
        @media screen and (min-width: ${rem(1200)}) and (max-width: ${rem(1550)}) {
            max-width: 80%;
            padding-top: 2em;
            padding-bottom: 1em;
        }

    `,

    Wrapper: styled(Wrapper)`
        width: 100vw;
        box-sizing: border-box;
        padding-top: ${fluid(30, 50, 'phone-xsmall', 'tablet')};

        ${mediaUp('tablet')} {
            padding-top: 14vh;
            padding-left: ${fluid(50, 100, 'tablet')};
            padding-right: 0;
        }
    `,

    Title: styled.h1`
        position: relative;
        z-index: 4;
        color: ${p => p.theme.colors.light};
        text-transform: uppercase;
        text-shadow: 0 0 10px rgba(0 0 0 / 0.4);
        font-size: ${p => p.theme.fontSizes.d1};
        font-weight: 900;
        max-width: 13em;

        @media screen and (min-width: ${rem(1200)}) and (max-height: ${rem(990)}) {
            font-size: ${fluid(40, 90, 'desktop-small')};
        }
    `,

    ImageWrapper: styled.figure`
        position: relative;
        height: 50vw;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to top, rgba(0 0 0 / .5) 50%, transparent 80%);
            pointer-events: none;
            z-index: 4;

            ${mediaUp('tablet')} {
                height: 35%;
            }
        }

        img {
            object-fit: cover;
        }
        
        .last-img {
            z-index: 3;
            max-width: ${rem(745)} !important;
            left: ${rem(20)} !important;
            object-fit: contain !important;
            object-position: bottom !important;
        }

        ${mediaUp('tablet')} {
            ${ratio(770, 1080)};

            position: absolute;
            top: 0;
            right: 0;
            width: ${fluid(384, 770, 'tablet')};
            min-height: 100vh;
            max-height: 100%; 
        }
    `,

    CtaImages: styled.div`
        opacity: ${p => p.active ? 1 : 0};
        transition: opacity 500ms;
    `,

    ImageCaption: styled.figcaption`
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translateX(-50%);
        max-width: ${fluid(250, 415)};
        width: 100%;
        z-index: 4;
    `,

    ImageHeadline: styled.p`
        font-size: ${p => p.theme.fontSizes.large};
        color: ${p => p.theme.colors.light};
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: ${fluid(10, 30)};
    `,

    ImageText: styled.p`
        font-size: ${p => p.theme.fontSizes.large};
        color: ${p => p.theme.colors.light};
    `,

    CollapsibleWrapper: styled.div`
        z-index: 3;
        position: relative;
        margin-top: ${rem(65)};
        max-width: ${fluid(500, 710, 'tablet')};
        margin-bottom: ${rem(30)};
        
        ${mediaUp('phone')} {
            margin-bottom: 0;
        }

        ${mediaUp('desktop-small')} {
            margin-top: ${fluid(65, 165, 'desktop-small')};
        }

        .collapsible {
            ${mediaUp('phone')} {
                margin-bottom: ${rem(30)};
            }

            &.active {
                .collapsible-header:before {
                    width: 0;
                }

                .collapsible-title {
                    color: ${p => p.theme.colors.light};
                    font-weight: 500;
                }
            }
        }
        
        .swiper-slide-active .collapsible .collapsible-title {
            color: white;
        }
    `,

    CollapsibleHeader: styled.header`
        ${mediaUp('phone')} {
            position: relative;
            padding-bottom: ${rem(30)};
        }
    `,

    CollapsibleTitle: styled.h3`
        color: white;
        font-size: ${p => p.theme.fontSizes.h3};
        transition: color ${p => p.theme.transitions.normal} ease;
        cursor: pointer;

        ${mediaUp('phone')} {
            color: ${p => p.theme.colors.gray('600')};
        }
    `,

    Slider: styled(Slider)`
        .swiper-slide {    
            width: 100%;
            height: auto !important;

            &:first-child {
                z-index: 9;
            }

            .suggestions-wrapper {
                position: fixed;
            }
        }

        ${mediaDown('phone')} {
            .swiper-slide {
                &:not(.swiper-slide-active) {
                    pointer-events: none;
                }
            }
        }

        ${mediaUp('phone')} {
            overflow: initial;

            .swiper-slide {    
                width: auto;
                height: auto;
            }

            .swiper-wrapper {
                display: block;

                @media screen and (min-width: ${rem(1200)}) and (max-height: ${rem(990)}) {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    height: ${rem(290)};
                }
            }
        }
    `
});