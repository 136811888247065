import AddressForm from 'components/address-form';
import Collapsible from 'components/collapsible';
import Wrapper from 'components/wrapper';
import Image from 'next/image';
import useHeaderContext from 'providers/header';
import s from 'styles/sections/homepage-hero';
import {useEffect, useRef, useState} from 'react';
import BPStripe from '../public/images/bp-stripe.png'

export default function HomepageHero ({fields}) {
	const {content, image: imageGroup} = fields;
	const {_, setHeaderOffset} = useHeaderContext();
	const mainRef = useRef(null);

	const [activeTab, setActiveTab] = useState(0);

	useEffect(() => {
		setHeaderOffset(mainRef.current.clientHeight);
	}, [mainRef]);

	/**
	 * Temp solution to hide siblings.
	 */
	const collapsibleNodes = new Map();

	const hideNonActive = (current) => {
		Array.from(collapsibleNodes.values())
			.map((tab, i) => {
				if (tab.el.isSameNode(current)) {
					setActiveTab(i);
				}

				if (!tab.el.isSameNode(current)) {
					tab.setState(false);
				} else {
					tab.el.querySelector('input')?.focus(); // I'll improve this in a near future.
				}
			});
	};

	const groupHeader = service => (
		<s.CollapsibleHeader className="collapsible-header">
			<s.CollapsibleTitle className="collapsible-title">{service.title}</s.CollapsibleTitle>
		</s.CollapsibleHeader>
	);

	const groupItem = (service, index) => {
		return (
			<>
				{index === 0 ? (
					<AddressForm
						fieldText={service.field_text}
						buttonText={service.button.title}
						buttonStyles={'light'}
					/>
				) : (
					<div>
						<s.Button
							styleName={'light'}
							href={service.button.url}
							target={service.button.target}
							iconName={'arrow-right'}>
							{service.button.title}
						</s.Button>
					</div>
				)}
			</>
		);
	};

	const sliderItems = items => (items.map((service, index) => {
		const firstIndex = index === 0;
		const isDesktop = window.innerWidth >= 768;

		return isDesktop ? (
			<Collapsible
				getRef={(ref, state, setState) => collapsibleNodes.set(service.title, {el: ref, setState, state})}
				onClick={hideNonActive}
				title={groupHeader(service)}
				active={firstIndex}
				key={service.title}
			>
				{groupItem(service, index)}
			</Collapsible>
		) : (
			<s.MobilePersona>
				{groupHeader(service)}
				{groupItem(service, index)};
			</s.MobilePersona>
		);
	}));

	return (
		<s.Section ref={mainRef} id={fields.anchor_id}>
			<Wrapper size="full" noPadding={true}>
				<s.TopDecoration/>
				<s.Wrapper>
					<s.TopContent maxWidth={1380}>
						{content.headline && <s.Title renderingpriority="high" rel="preload">{content.headline}</s.Title>}
					</s.TopContent>
					<s.HeaderStripe>
						<Image src={BPStripe} />
					</s.HeaderStripe>

					{content.ctas.length && <s.CollapsibleWrapper>
						<s.Slider
							spaceBetween={20}
							pagination={true}
							items={sliderItems(content.ctas)}
							mobileOnly={true}
						/>
					</s.CollapsibleWrapper>}
				</s.Wrapper>

				<s.BottomDecoration/>
				<s.BottomDecorationMobile/>

				<s.ImageWrapper>
					{content.ctas.map((cta, ctaKey) => (
						<s.CtaImages active={ctaKey === activeTab} key={ctaKey}>
							<Image
								src={cta.back_image?.sizes?.large}
								alt={cta.back_image?.alt}
								placeholder="blur"
								blurDataURL={cta.back_image?.sizes?.large} 
								layout="fill"
								quality={50} 
								priority="false"
							/>

							<Image
								src={cta.front_image?.sizes?.large}
								alt={cta.front_image?.alt}
								placeholder="blur"
								blurDataURL={cta.front_image?.sizes?.large}
								layout="fill"
								className="last-img"
								quality={50} 
								priority="false"
							/>
						</s.CtaImages>
					))}

					<s.ImageCaption>
						{imageGroup.headline && <s.ImageHeadline>{imageGroup.headline}</s.ImageHeadline>}
						{imageGroup.text && <s.ImageText>{imageGroup.text}</s.ImageText>}
					</s.ImageCaption>
				</s.ImageWrapper>
			</Wrapper>
			
		</s.Section>
	);
}
