import Head from "next/head";

const PageHead = ({customTitle = '', pageSEO}) => {
    return (
        <Head>
            <title>{customTitle || pageSEO.title}</title>

            {pageSEO && (
                <>
                    <meta name="description" content={pageSEO?.metaDescription || ''}/>
                    <meta name="keywords" content={pageSEO?.metaKeywords || ''}/>

                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={pageSEO?.ogTitle || ''}/>
                    <meta property="og:description" content={pageSEO?.ogDescription || ''}/>
                    <meta property="og:image" content={pageSEO?.ogImage || ''}/>

                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content={pageSEO?.ogTitle || ''}/>
                    <meta name="twitter:description" content={pageSEO?.ogDescription || ''}/>
                    <meta name="twitter:image" content={pageSEO?.ogImage || ''}/>
                </>
            )}
        </Head>
    );
}

export default PageHead;
