import HomepageHero from 'sections/homepage-hero';
import dynamic from 'next/dynamic';
import Page from 'components/page';
import PageHead from 'components/page-head';
import wpApi from 'util/wp-api';
import {SiteOptionsProvider} from 'providers/useOptions';

export async function getStaticProps () {

	// Retrieve site options and page data
	const siteOptions = await wpApi.request('site-options');
	const pageData = await wpApi.page('/');

	// Prepare SEO data
	const pageSEO = {
		title: pageData?.site_info?.head_title || 'BlocPower',
		metaDescription: pageData?.fields.meta_description || null,
		metaKeywords: pageData?.fields.meta_keywords || null,
		ogTitle: pageData?.site_info?.og_title || pageData?.title || null,
		ogDescription: pageData?.fields.meta_description || null,
		ogImage: pageData?.thumbnail?.sizes.large || null,
	};

	return {props: {siteOptions, pageData, pageSEO}};

}

export default function Home (props) {
	const Page = dynamic(() => import('components/page'));

	return (
		<>
			<PageHead pageSEO={props.pageSEO} />

			<SiteOptionsProvider value={props.siteOptions}>
				<Page pageData={props.pageData}>
					{sections => (
						<>
							{props.pageData?.fields?.hero && <HomepageHero fields={props.pageData.fields.hero}/>}
							{sections}
						</>
					)}
				</Page>
			</SiteOptionsProvider>
		</>
	);
}
